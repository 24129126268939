<template>
  <v-form v-model="validateUser">
    <v-container
      style="background-color:#fafafa; max-width:650px;"
      class="pa-9"
      rounded
    >
      <v-row>
        <v-col cols="12" class="text-center">
          <h1>
            Activa tu Garantía
          </h1>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Nombre"
            v-model="garantia.nombre"
            prepend-icon="mdi-account"
            :rules="requiredRules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Apellido paterno"
            prepend-icon="mdi-account"
            v-model="garantia.apellidoPaterno"
            :rules="requiredRules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Apellido materno"
            prepend-icon="mdi-account"
            v-model="garantia.apellidoMaterno"
            :rules="requiredRules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="garantia.date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="garantia.date"
                label="Fecha de venta"
                prepend-icon="mdi-calendar"
                :rules="requiredRules"
                required
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="garantia.date" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(garantia.date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Teléfono"
            type="number"
            :rules="requiredRules"
            required
            prepend-icon="mdi-cellphone"
            v-model="garantia.telefono"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Email"
            :rules="emailRules"
            required
            v-model="garantia.email"
            prepend-icon="mdi-email"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="estados"
            v-model="garantia.estadoRepublica"
            prepend-icon="mdi-home-group"
            label="Estado de la república"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Ciudad"
            v-model="garantia.ciudad"
            :rules="requiredRules"
            required
            prepend-icon="mdi-city"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="¿Dónde adquirió su moto?"
            prepend-icon="mdi-motorbike"
            :rules="requiredRules"
            required
            v-model="garantia.dondeAdquirio"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Número de serie"
            prepend-icon="mdi-motorbike"
            :rules="requiredRules"
            required
            v-model="garantia.numeroSerie"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="modelos"
            v-model="garantia.modelo"
            prepend-icon="mdi-ticket-percent"
            label="Modelo"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="Número de cupón"
            prepend-icon="mdi-ticket-percent"
            :rules="requiredRules"
            required
            v-model="garantia.numeroCupon"
          ></v-text-field>
          <em>(Número de folio - pág. 14 póliza)</em>
        </v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="garantia.adquirioPlan"
            label="¿Adquirió plan de mantenimiento anual?"
          ></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-btn
            color="primary"
            large
            block
            :loading="cargando"
            :disabled="disable"
            rounded
            class="white--text"
            @click="insertaGarantia"
            >Enviar</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import PostService from "../PostService";
import rules from "../../mixins/rules";
export default {
  name: "garantias",
  mixins: [rules],
  data: () => ({
    validateUser: false,
    garantia: {
      nombre: "",
      date: null,
      apellidoPaterno: "",
      apellidoMaterno: "",
      telefono: "",
      email: "",
      estadoRepublica: "",
      ciudad: "",
      dondeAdquirio: "",
      numeroSerie: "",
      modelo: "",
      numeroCupon: "",
      adquirioPlan: false
    },
    modelos: [
      "DOMINAR D 400 ABS-EXP",
      "DOMINAR 400 UG",
      "BOXER 150 BM",
      "PLATINA 125",
      "PLATINA 100",
      "PULSAR 125 NS",
      "PULSAR 150 Neon",
      "PULSAR 220 Neon",
      "PULSAR NS 200",
      "PULSAR 200 FI ABS",
      "PULSAR 200 RS",
      "PULSAR NS 160 TD",
      "AVENGER 220 CRUISE",
      "DOMINAR 250 UG",
      "PULSAR N250",
    ],
    estados: [
      "Aguascalientes",
      "Baja California",
      "Baja California Sur",
      "Campeche",
      "CDMX",
      "Coahuila",
      "Colima",
      "Chiapas",
      "Durango",
      "Guanajuato",
      "Guerrero",
      "Hidalgo",
      "Jalisco",
      "Estado de México",
      "Michoacán",
      "Morelos",
      "Nayarit",
      "Nuevo León",
      "Oaxaca",
      "Puebla",
      "Querétaro",
      "Quintana Roo",
      "San Luis Potosí",
      "Sinaloa",
      "Sonora",
      "Tabasco",
      "Tamaulipas",
      "Tlaxcala",
      "Veracruz",
      "Yucatán",
      "Zacatecas"
    ],
    cargando: false,
    disable: false
  }),
  methods: {
    async insertaGarantia() {
      try {
        this.disable = true;
        this.cargando = true;
        if (!this.validateUser) {
          this.cargando = false;
          this.disable = false;
          return this.$store.dispatch("setSnackBarProps", {
            color: "error",
            mensaje: "Algún campo falta. Por favor intenta de nuevo.",
            mostrar: true
          });
        }
        const respuesta = await PostService.postActivarGarantia(this.garantia);
        this.cargando = false;
        this.$store.dispatch("setSnackBarProps", {
          color: "success",
          mensaje: respuesta.data,
          mostrar: true
        });
        this.$router.push("/exito");
      } catch (error) {
        this.$store.dispatch("setSnackBarProps", {
          color: "error",
          mensaje: "Ha habido un error. Intente de nuevo.",
          mostrar: true
        });
      }
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
h1 {
  font-family: "Open Sans", sans-serif;
  color: rgb(59, 59, 59);
  font-size: 38px;
}
h2 {
  font-family: "Open Sans", sans-serif;
  color: rgb(92, 92, 92);
  font-size: 17px;
}
</style>
